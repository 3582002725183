@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,700");
input[type=submit].aw-btn,
input[type=reset].aw-btn,
input[type=button].aw-btn,
div.aw-btn,
label.aw-btn,
button.aw-btn,
a.aw-btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 16px;
  padding: 6px 20px;
}
input[type=submit].aw-btn:hover, input[type=submit].aw-btn:focus,
input[type=reset].aw-btn:hover,
input[type=reset].aw-btn:focus,
input[type=button].aw-btn:hover,
input[type=button].aw-btn:focus,
div.aw-btn:hover,
div.aw-btn:focus,
label.aw-btn:hover,
label.aw-btn:focus,
button.aw-btn:hover,
button.aw-btn:focus,
a.aw-btn:hover,
a.aw-btn:focus {
  text-decoration: none;
}
input[type=submit].aw-btn:disabled, input[type=submit].aw-btn[disabled],
input[type=reset].aw-btn:disabled,
input[type=reset].aw-btn[disabled],
input[type=button].aw-btn:disabled,
input[type=button].aw-btn[disabled],
div.aw-btn:disabled,
div.aw-btn[disabled],
label.aw-btn:disabled,
label.aw-btn[disabled],
button.aw-btn:disabled,
button.aw-btn[disabled],
a.aw-btn:disabled,
a.aw-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}
input[type=submit].aw-btn:active,
input[type=reset].aw-btn:active,
input[type=button].aw-btn:active,
div.aw-btn:active,
label.aw-btn:active,
button.aw-btn:active,
a.aw-btn:active {
  background-image: none;
}
input[type=submit].aw-btn .icon-AW_ArrowRight,
input[type=reset].aw-btn .icon-AW_ArrowRight,
input[type=button].aw-btn .icon-AW_ArrowRight,
div.aw-btn .icon-AW_ArrowRight,
label.aw-btn .icon-AW_ArrowRight,
button.aw-btn .icon-AW_ArrowRight,
a.aw-btn .icon-AW_ArrowRight {
  font-size: 0.6em;
}
input[type=submit].aw-btn-cta,
input[type=reset].aw-btn-cta,
input[type=button].aw-btn-cta,
div.aw-btn-cta,
label.aw-btn-cta,
button.aw-btn-cta,
a.aw-btn-cta {
  min-width: 200px;
  border-radius: 4px;
  font-size: 20px;
  padding: 6px 20px;
}
input[type=submit].aw-btn-lg,
input[type=reset].aw-btn-lg,
input[type=button].aw-btn-lg,
div.aw-btn-lg,
label.aw-btn-lg,
button.aw-btn-lg,
a.aw-btn-lg {
  border-radius: 4px;
  font-size: 20px;
  padding: 6px 20px;
}
input[type=submit].aw-btn-md,
input[type=reset].aw-btn-md,
input[type=button].aw-btn-md,
div.aw-btn-md,
label.aw-btn-md,
button.aw-btn-md,
a.aw-btn-md {
  border-radius: 4px;
  font-size: 16px;
  padding: 6px 20px;
}
input[type=submit].aw-btn-sm,
input[type=reset].aw-btn-sm,
input[type=button].aw-btn-sm,
div.aw-btn-sm,
label.aw-btn-sm,
button.aw-btn-sm,
a.aw-btn-sm {
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 14px;
}
input[type=submit].aw-btn-blue,
input[type=reset].aw-btn-blue,
input[type=button].aw-btn-blue,
div.aw-btn-blue,
label.aw-btn-blue,
button.aw-btn-blue,
a.aw-btn-blue {
  background-color: #25abfd;
  border-color: #25abfd;
  color: #fff;
}
input[type=submit].aw-btn-blue:hover,
input[type=reset].aw-btn-blue:hover,
input[type=button].aw-btn-blue:hover,
div.aw-btn-blue:hover,
label.aw-btn-blue:hover,
button.aw-btn-blue:hover,
a.aw-btn-blue:hover {
  background-color: #1a99e8;
}
input[type=submit].aw-btn-blue:hover:not(:focus),
input[type=reset].aw-btn-blue:hover:not(:focus),
input[type=button].aw-btn-blue:hover:not(:focus),
div.aw-btn-blue:hover:not(:focus),
label.aw-btn-blue:hover:not(:focus),
button.aw-btn-blue:hover:not(:focus),
a.aw-btn-blue:hover:not(:focus) {
  border-color: #1a99e8;
}
input[type=submit].aw-btn-green,
input[type=reset].aw-btn-green,
input[type=button].aw-btn-green,
div.aw-btn-green,
label.aw-btn-green,
button.aw-btn-green,
a.aw-btn-green {
  background-color: #66d448;
  border-color: #66d448;
  color: #fff;
}
input[type=submit].aw-btn-green:hover,
input[type=reset].aw-btn-green:hover,
input[type=button].aw-btn-green:hover,
div.aw-btn-green:hover,
label.aw-btn-green:hover,
button.aw-btn-green:hover,
a.aw-btn-green:hover {
  background-color: #5bc23e;
}
input[type=submit].aw-btn-green:hover:not(:focus),
input[type=reset].aw-btn-green:hover:not(:focus),
input[type=button].aw-btn-green:hover:not(:focus),
div.aw-btn-green:hover:not(:focus),
label.aw-btn-green:hover:not(:focus),
button.aw-btn-green:hover:not(:focus),
a.aw-btn-green:hover:not(:focus) {
  border-color: #5bc23e;
}
input[type=submit].aw-btn-yellow, input[type=submit].aw-btn-orange,
input[type=reset].aw-btn-yellow,
input[type=reset].aw-btn-orange,
input[type=button].aw-btn-yellow,
input[type=button].aw-btn-orange,
div.aw-btn-yellow,
div.aw-btn-orange,
label.aw-btn-yellow,
label.aw-btn-orange,
button.aw-btn-yellow,
button.aw-btn-orange,
a.aw-btn-yellow,
a.aw-btn-orange {
  background-color: #fdb53e;
  border-color: #fdb53e;
  color: #fff;
}
input[type=submit].aw-btn-yellow:hover, input[type=submit].aw-btn-orange:hover,
input[type=reset].aw-btn-yellow:hover,
input[type=reset].aw-btn-orange:hover,
input[type=button].aw-btn-yellow:hover,
input[type=button].aw-btn-orange:hover,
div.aw-btn-yellow:hover,
div.aw-btn-orange:hover,
label.aw-btn-yellow:hover,
label.aw-btn-orange:hover,
button.aw-btn-yellow:hover,
button.aw-btn-orange:hover,
a.aw-btn-yellow:hover,
a.aw-btn-orange:hover {
  background-color: #eda42f;
}
input[type=submit].aw-btn-yellow:hover:not(:focus), input[type=submit].aw-btn-orange:hover:not(:focus),
input[type=reset].aw-btn-yellow:hover:not(:focus),
input[type=reset].aw-btn-orange:hover:not(:focus),
input[type=button].aw-btn-yellow:hover:not(:focus),
input[type=button].aw-btn-orange:hover:not(:focus),
div.aw-btn-yellow:hover:not(:focus),
div.aw-btn-orange:hover:not(:focus),
label.aw-btn-yellow:hover:not(:focus),
label.aw-btn-orange:hover:not(:focus),
button.aw-btn-yellow:hover:not(:focus),
button.aw-btn-orange:hover:not(:focus),
a.aw-btn-yellow:hover:not(:focus),
a.aw-btn-orange:hover:not(:focus) {
  border-color: #eda42f;
}
input[type=submit].aw-btn-red,
input[type=reset].aw-btn-red,
input[type=button].aw-btn-red,
div.aw-btn-red,
label.aw-btn-red,
button.aw-btn-red,
a.aw-btn-red {
  background-color: #fd6b6b;
  border-color: #fd6b6b;
  color: #fff;
}
input[type=submit].aw-btn-red:hover,
input[type=reset].aw-btn-red:hover,
input[type=button].aw-btn-red:hover,
div.aw-btn-red:hover,
label.aw-btn-red:hover,
button.aw-btn-red:hover,
a.aw-btn-red:hover {
  background-color: #e85858;
}
input[type=submit].aw-btn-red:hover:not(:focus),
input[type=reset].aw-btn-red:hover:not(:focus),
input[type=button].aw-btn-red:hover:not(:focus),
div.aw-btn-red:hover:not(:focus),
label.aw-btn-red:hover:not(:focus),
button.aw-btn-red:hover:not(:focus),
a.aw-btn-red:hover:not(:focus) {
  border-color: #e85858;
}
input[type=submit].aw-btn-purple,
input[type=reset].aw-btn-purple,
input[type=button].aw-btn-purple,
div.aw-btn-purple,
label.aw-btn-purple,
button.aw-btn-purple,
a.aw-btn-purple {
  background-color: #9485ed;
  border-color: #9485ed;
  color: #fff;
}
input[type=submit].aw-btn-purple:hover,
input[type=reset].aw-btn-purple:hover,
input[type=button].aw-btn-purple:hover,
div.aw-btn-purple:hover,
label.aw-btn-purple:hover,
button.aw-btn-purple:hover,
a.aw-btn-purple:hover {
  background-color: #7a6bcf;
}
input[type=submit].aw-btn-purple:hover:not(:focus),
input[type=reset].aw-btn-purple:hover:not(:focus),
input[type=button].aw-btn-purple:hover:not(:focus),
div.aw-btn-purple:hover:not(:focus),
label.aw-btn-purple:hover:not(:focus),
button.aw-btn-purple:hover:not(:focus),
a.aw-btn-purple:hover:not(:focus) {
  border-color: #7a6bcf;
}
input[type=submit].aw-btn-darkblue,
input[type=reset].aw-btn-darkblue,
input[type=button].aw-btn-darkblue,
div.aw-btn-darkblue,
label.aw-btn-darkblue,
button.aw-btn-darkblue,
a.aw-btn-darkblue {
  background-color: #1774cc;
  border-color: #1774cc;
  color: #fff;
}
input[type=submit].aw-btn-darkblue:hover,
input[type=reset].aw-btn-darkblue:hover,
input[type=button].aw-btn-darkblue:hover,
div.aw-btn-darkblue:hover,
label.aw-btn-darkblue:hover,
button.aw-btn-darkblue:hover,
a.aw-btn-darkblue:hover {
  background-color: #12aefc;
}
input[type=submit].aw-btn-darkblue:hover:not(:focus),
input[type=reset].aw-btn-darkblue:hover:not(:focus),
input[type=button].aw-btn-darkblue:hover:not(:focus),
div.aw-btn-darkblue:hover:not(:focus),
label.aw-btn-darkblue:hover:not(:focus),
button.aw-btn-darkblue:hover:not(:focus),
a.aw-btn-darkblue:hover:not(:focus) {
  border-color: #12aefc;
}
input[type=submit].aw-btn-outlines,
input[type=reset].aw-btn-outlines,
input[type=button].aw-btn-outlines,
div.aw-btn-outlines,
label.aw-btn-outlines,
button.aw-btn-outlines,
a.aw-btn-outlines {
  background-color: #fff;
  border-color: #1774cc;
  border-width: 1px;
  color: #1774cc;
}
input[type=submit].aw-btn-outlines:hover, input[type=submit].aw-btn-outlines:focus,
input[type=reset].aw-btn-outlines:hover,
input[type=reset].aw-btn-outlines:focus,
input[type=button].aw-btn-outlines:hover,
input[type=button].aw-btn-outlines:focus,
div.aw-btn-outlines:hover,
div.aw-btn-outlines:focus,
label.aw-btn-outlines:hover,
label.aw-btn-outlines:focus,
button.aw-btn-outlines:hover,
button.aw-btn-outlines:focus,
a.aw-btn-outlines:hover,
a.aw-btn-outlines:focus {
  border-color: #12aefc;
  color: #12aefc;
  box-shadow: none;
}
input[type=submit].aw-btn-reverse,
input[type=reset].aw-btn-reverse,
input[type=button].aw-btn-reverse,
div.aw-btn-reverse,
label.aw-btn-reverse,
button.aw-btn-reverse,
a.aw-btn-reverse {
  border-color: #fff;
  border-width: 1px;
  color: #fff;
}
input[type=submit].aw-btn-reverse:hover,
input[type=reset].aw-btn-reverse:hover,
input[type=button].aw-btn-reverse:hover,
div.aw-btn-reverse:hover,
label.aw-btn-reverse:hover,
button.aw-btn-reverse:hover,
a.aw-btn-reverse:hover {
  background-color: #fff;
  color: #12aefc;
}

#header {
  background-color: rgba(0, 0, 0, 0.45);
  height: 54px;
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 9999;
}
@media (max-width: 480px) {
  #header.auto-hide:not([data-type=application]) {
    top: -54px;
  }
}
#header #nav-primary {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 54px;
  margin: 0 auto;
  max-width: 960px;
  overflow: hidden;
}
#header #nav-primary #logo {
  border: 0;
  float: left;
  height: 38px;
  margin-left: 10px;
  margin-top: 8px;
}
#header #nav-primary #nav-controls {
  float: right;
  height: 54px;
  margin-right: 10px;
  overflow: hidden;
}
#header #nav-primary #nav-controls .cta {
  background-clip: padding-box;
  border-radius: 5px;
  border: 1px solid #66d448;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 32px;
  margin-top: 10px;
  outline: 0;
  padding: 0 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
#header #nav-primary #nav-controls .cta:hover {
  background-color: #5bc23e;
  border-color: #5bc23e;
}
#header #nav-primary #nav-controls .cta.highlight {
  background-color: #66d448;
  border-color: #66d448;
}
#header #nav-primary #nav-controls .cta.highlight:hover {
  background-color: #5bc23e;
  border-color: #5bc23e;
}
#header #nav-primary #nav-controls .cta.cta-application {
  display: none;
}
#header[data-type=application] .cta {
  display: none !important;
}
#header[data-type=application] .cta-application {
  display: inline-block !important;
}
#header[data-type=logo-only] #nav-controls {
  display: none !important;
}

#nav-slider {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 888;
}
@media (max-width: 760px) {
  #nav-slider.is-active {
    display: block;
  }
}

@media (max-width: 760px) {
  html.no-scroll,
  html.no-scroll body {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  html.no-scroll body {
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
  }
}
@keyframes flyin {
  from {
    left: 100%;
  }
}
@keyframes flyout {
  to {
    left: 100%;
  }
}
.container {
  max-width: 960px;
}

.modal {
  z-index: 99999;
}

.center {
  text-align: center;
}

.clear {
  clear: both;
}

.error-page {
  margin: 0 auto;
  max-width: 500px;
  padding: 100px 30px;
}
.error-page h1 {
  font-size: 100px;
}
@media (min-width: 630px) {
  .error-page {
    padding: 200px 30px;
  }
}

.page-submit-button {
  margin-top: 50px;
}

@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../../../../pixel/assets/fonts/icomoon.eot?3rvdx3");
  src: url("../../../../pixel/assets/fonts/icomoon.eot?3rvdx3#iefix") format("embedded-opentype"), url("../../../../pixel/assets/fonts/icomoon.ttf?3rvdx3") format("truetype"), url("../../../../pixel/assets/fonts/icomoon.woff?3rvdx3") format("woff"), url("../../../../pixel/assets/fonts/icomoon.svg?3rvdx3#icomoon") format("svg");
}
[class^=icon-AW],
[class*=" icon-AW"] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
select:focus,
textarea {
  font-size: 16px;
}
@media (min-width: 630px) {
  input[type=color],
  input[type=date],
  input[type=datetime],
  input[type=datetime-local],
  input[type=email],
  input[type=month],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  input[type=week],
  select,
  select:focus,
  textarea {
    font-size: 14px;
  }
}

input[readonly] {
  color: #aaa !important;
  cursor: not-allowed;
}

.tooltip > .tooltip-inner {
  -moz-box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.25);
  background-color: #f2f2f2;
  border: 1px solid #cbcbcb;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.25);
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 15px;
  max-width: none;
  padding: 20px;
  text-align: left;
  width: 290px;
}

.tooltip.top > .tooltip-arrow {
  border-top: 5px solid #cbcbcb;
}

.tooltip.in {
  opacity: 1 !important;
}

body {
  color: #666;
  counter-reset: step;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: #0469bd;
  white-space: nowrap;
}
a:hover, a:focus {
  color: #25abfd;
}

.hero {
  background-image: url("../../../../pixel/assets/images/img_wood_3128x596.jpg");
  background-position: top center;
  background-size: cover;
  color: #fff;
  height: 237px;
  overflow: hidden;
  padding-top: 64px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .hero {
    height: 300px;
  }
}
.hero .hero-inner {
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: calc(50% + 27px);
  transform: translate(-50%, -50%);
  width: 100%;
}
.hero .hero-inner h1 {
  -webkit-font-smoothing: antialiased;
  font-size: 34px;
  font-weight: 300;
  line-height: 1.2;
  margin: 8px 0 0;
  width: auto;
}
@media (min-width: 768px) {
  .hero .hero-inner h1 {
    font-size: 60px;
    margin-bottom: 0;
    padding: 0;
    width: auto;
  }
}
.hero .hero-inner img.icon {
  height: auto;
  margin-top: 10px;
  width: 100px;
}
.hero .hero-inner img.icon.done {
  width: 71px;
}
@media (min-width: 768px) {
  .hero .hero-inner img.icon {
    width: 125px;
  }
  .hero .hero-inner img.icon.done {
    width: 89px;
  }
}
.hero::before {
  border-bottom: 20px solid #5d51a2;
  border-left: 20px solid #5d51a2;
  border-right: 20px solid transparent;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  width: 50%;
}
.hero::after {
  border-bottom: 20px solid #5d51a2;
  border-left: 20px solid transparent;
  border-right: 20px solid #5d51a2;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  width: 50%;
}

.hero-notchless::before, .hero-notchless::after {
  border: 0;
}

.full-width-gray {
  background-color: #f2f2f2;
}

.constrain-width-on-mobile-to-620 {
  margin: 0 auto;
  max-width: 328px;
}
@media (min-width: 630px) {
  .constrain-width-on-mobile-to-620 {
    max-width: 656px;
  }
}
@media (min-width: 768px) {
  .constrain-width-on-mobile-to-620 {
    max-width: 100%;
  }
}

#footer {
  background: #383b3f;
  color: #fff;
  padding: 10px;
  text-align: center;
}
#footer .legal {
  text-transform: uppercase;
}
#footer img {
  height: auto;
  margin-bottom: 15px;
  margin-top: 28px;
  width: 200px;
}
@media (min-width: 630px) {
  #footer img {
    width: 300px;
  }
}
#footer p {
  color: #ccc;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  padding-bottom: 16px;
}
@media (min-width: 630px) {
  #footer p {
    font-size: 12px;
    line-height: 21px;
  }
}
#footer p a {
  color: #ccc;
  cursor: pointer;
  margin-left: 14px;
  text-decoration: underline;
}
#footer p a#terms-of-use {
  margin-right: 14px;
}

.page {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  text-align: center;
}
.page #main-page-container {
  padding: 32px 15px 80px;
}
@media (min-width: 768px) {
  .page #main-page-container {
    padding: 32px 0 80px;
  }
}
.page #main-page-container.no-padding {
  padding: 0;
}
.page #main-page-container.follows-progress {
  padding-top: 20px;
}
.page #main-page-container p {
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

#progress {
  background-color: #f5f5f5;
  overflow-x: hidden;
  padding: 22px 0 44px;
}
@media (min-width: 630px) {
  #progress {
    margin-bottom: 25px;
    padding: 40px 0 84px;
  }
}
#progress .bar {
  background-color: #dbdbdb;
  display: flex;
  height: 5px;
  justify-content: space-between;
  margin: 0 5px;
}
@media (min-width: 630px) {
  #progress .bar {
    height: 12px;
    margin: 0 15px;
  }
}
@media (min-width: 1199px) {
  #progress .bar {
    margin: 0 65px;
  }
}
#progress .bar .step-group {
  cursor: default;
  display: block;
  margin-top: -12px;
  position: relative;
}
@media (min-width: 630px) {
  #progress .bar .step-group {
    margin-top: -19px;
  }
}
#progress .bar .step-group[href] {
  cursor: pointer;
}
#progress .bar .step-group:hover, #progress .bar .step-group:active, #progress .bar .step-group:focus {
  text-decoration: none;
}
#progress .bar .step-group div.circle-num {
  background-color: #6e8093;
  border-radius: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
#progress .bar .step-group div.circle-num::before {
  content: counter(step);
  counter-increment: step;
}
@media (min-width: 630px) {
  #progress .bar .step-group div.circle-num {
    font-size: 27px;
    height: 54px;
    line-height: 54px;
    width: 54px;
  }
}
#progress .bar .step-group div.circle-done {
  background-color: #71df54;
  background-image: linear-gradient(#71df54, #30a039);
  border-radius: 100%;
  color: #fff;
  display: none;
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
#progress .bar .step-group div.circle-done::before {
  counter-increment: step;
}
@media (min-width: 630px) {
  #progress .bar .step-group div.circle-done {
    font-size: 27px;
    height: 54px;
    line-height: 54px;
    width: 54px;
  }
}
#progress .bar .step-group .step-desc {
  color: #666;
  font-size: 11px;
  left: 50%;
  line-height: 1;
  margin: 5px 0 0;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  white-space: normal;
  width: 45px;
}
@media (min-width: 630px) {
  #progress .bar .step-group .step-desc {
    font-size: 14px;
    margin: 10px 0 0;
    width: 100px;
  }
}
@media (min-width: 1199px) {
  #progress .bar .step-group .step-desc {
    width: 120px;
  }
}
#progress .bar .step-group .step-desc.hide-on-mobile {
  display: none;
}
@media (min-width: 630px) {
  #progress .bar .step-group .step-desc.hide-on-mobile {
    display: block;
  }
}
@media (min-width: 630px) {
  #progress .bar .step-group .step-desc.hide-on-desktop {
    display: none;
  }
}
#progress .bar .active .circle-num {
  background-image: linear-gradient(#25abfd, #0469bd);
}
#progress .bar .active .step-desc {
  color: #333;
  font-weight: 600;
}
#progress .bar .completed {
  cursor: pointer;
}
#progress .bar .completed div.circle-num {
  display: none;
}
#progress .bar .completed div.circle-done {
  display: inline-block;
}

.page-intro {
  overflow-x: hidden;
  text-align: center;
}
.page-intro.purple-background {
  background-color: #5d51a2;
}
.page-intro.purple-background h2 {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 15px 0 30px;
}
@media (min-width: 768px) {
  .page-intro.purple-background h2 {
    font-size: 20px;
    line-height: 30px;
    padding: 45px 0 50px;
  }
}
.page-intro h2 {
  font-size: 20px;
  line-height: 1.2;
  margin: 0 0 10px;
}
@media (min-width: 630px) {
  .page-intro h2 {
    font-size: 30px;
    margin-bottom: 12px;
  }
}
.page-intro p {
  margin: 0;
}

.section-header {
  margin-top: 28px;
}
@media (min-width: 630px) {
  .section-header {
    margin-top: 25px;
  }
}
.section-header h2 {
  border-bottom: 1px solid #ccc;
  color: #333;
  font-size: 19px;
  margin: 0 0 15px;
  padding-bottom: 8px;
}
@media (min-width: 630px) {
  .section-header h2 {
    font-size: 25px;
    padding-bottom: 16px;
  }
}
.section-header h2 span {
  font-size: 15px;
}
@media (min-width: 630px) {
  .section-header h2 span {
    font-size: 25px;
  }
}
.section-header h2 span.gray-circle-num {
  background-color: #c9c9c9;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  height: 24px;
  line-height: 22px;
  margin-right: 6px;
  margin-top: -4px;
  text-align: center;
  vertical-align: middle;
  width: 24px;
}
@media (min-width: 630px) {
  .section-header h2 span.gray-circle-num {
    font-size: 26px;
    height: 40px;
    line-height: 38px;
    margin-right: 10px;
    width: 40px;
  }
}

@media (min-width: 768px) {
  .gray-horz-rule {
    border-bottom: 1px solid #ccc;
    display: block;
    margin: 30px 0 40px;
  }
}

.gray-well {
  background-color: #f2f2f2;
  border: 1px solid #cbcbcb;
  margin-bottom: 26px;
  padding: 20px;
  text-align: center;
}
@media (min-width: 630px) {
  .gray-well {
    margin-bottom: 36px;
    margin-top: 38px;
    text-align: left;
  }
}
.gray-well ol {
  margin-bottom: 30px;
  padding: 0 0 0 20px;
  text-align: left;
}
.gray-well ol li {
  margin-bottom: 15px;
}
.gray-well ol li:last-child {
  margin-bottom: 0;
}

.components {
  font-family: "Open Sans", sans-serif;
  text-align: left;
}
.components .center {
  text-align: center;
}
.components .center-mobile-only {
  text-align: center;
}
@media (min-width: 768px) {
  .components .center-mobile-only {
    text-align: left;
  }
}
.components .form-element {
  border: 1px solid transparent;
  box-sizing: border-box;
  margin-bottom: 12px;
  min-height: 65px;
}
.components .form-element label,
.components .form-element .radio-label,
.components .form-element .checkbox-label,
.components .form-element .checkbox-label-flexbox,
.components .form-element .textarea-label {
  color: #333;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 4px 0 0;
  padding: 0 0 5px;
  position: relative;
}
.components .form-element label .question-icon,
.components .form-element .radio-label .question-icon,
.components .form-element .checkbox-label .question-icon,
.components .form-element .checkbox-label-flexbox .question-icon,
.components .form-element .textarea-label .question-icon {
  background-image: url("../../../../pixel/assets/images/icon_tiny_question_mark_37x37.gif");
  background-size: 18px 18px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-bottom: -10px;
  margin-left: 4px;
  position: relative;
  top: -6px;
  width: 18px;
}
.components .form-element label .text-link,
.components .form-element .radio-label .text-link,
.components .form-element .checkbox-label .text-link,
.components .form-element .checkbox-label-flexbox .text-link,
.components .form-element .textarea-label .text-link {
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
}
.components .form-element .checkbox-label {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.components .form-element .checkbox-label-flexbox {
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding-left: 50px;
  padding-top: 4px;
}
.components .form-element .checkbox-label-flexbox.residency {
  margin: 0;
  padding: 0;
  position: static;
}
.components .form-element .checkbox-label-flexbox span.help-block,
.components .form-element .checkbox-label-flexbox ul.list-unstyled {
  margin-bottom: 0 !important;
}
.components .form-element .checkbox-label-flexbox a {
  outline: none;
}
.components .form-element.no-label {
  margin-bottom: 4px;
  min-height: 40px;
}
.components .form-element.no-label.form-select-input::after {
  top: 9px;
}
.components .form-element.no-label.form-text-input::after {
  top: 9px;
}
.components .form-element.no-label.form-text-input .other-credential-or-specialty {
  color: #313336;
  padding-top: 10px;
}
.components .form-element.no-vert-padding {
  min-height: auto;
  padding-top: 0;
}
.components .button a,
.components .button label {
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 21px;
  font-weight: normal;
  height: 50px;
  line-height: 26px;
  max-width: 300px;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}
.components .button a:hover,
.components .button label:hover {
  color: #fff;
}
.components .button.button-green a, .components .button.button-green label {
  background-color: #71df54;
  background-image: linear-gradient(#71df54, #30a039);
}
.components .button.button-green a:hover, .components .button.button-green label:hover {
  background-color: #30a039;
  background-image: linear-gradient(#30a039, #71df54);
}
.components .button.button-blue a, .components .button.button-blue label {
  background-color: #25abfd;
  background-image: linear-gradient(#25abfd, #0469bd);
}
.components .button.button-blue a:hover, .components .button.button-blue label:hover {
  background-color: #0469bd;
  background-image: linear-gradient(#0469bd, #25abfd);
}
.components input[type=file] {
  visibility: hidden;
}
.components label.link {
  color: #25abfd;
  cursor: pointer;
  font-weight: normal;
}
.components .large-text-link {
  padding: 0;
}
.components .large-text-link a {
  font-size: 21px;
  font-weight: normal;
  line-height: 26px;
  text-decoration: none;
}
.components .large-text-link a:hover {
  text-decoration: underline;
}
.components .large-text-link span.glyphicon {
  font-size: 14px;
  font-weight: 600;
}
.components .form-text-input {
  position: relative;
}
.components .form-text-input input[type=text],
.components .form-text-input input[type=password],
.components .form-text-input input[type=email],
.components .form-text-input input[type=number],
.components .form-text-input input[type=tel],
.components .form-text-input input[type=date] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  bottom: 0;
  color: #666;
  display: block;
  height: 38px;
  margin: 0 !important;
  padding: 5px 10px;
  position: relative;
  width: 100%;
}
.components .form-text-input.financial-info-container {
  display: none;
}
.components .form-text-input .dollar-sign-wrapper {
  position: relative;
}
.components .form-text-input .dollar-sign-wrapper::before {
  color: #666;
  content: "$";
  left: 12px;
  position: absolute;
  top: 9px;
  z-index: 1;
}
.components .form-text-input .dollar-sign-wrapper input[type=text] {
  padding-left: 19px !important;
}
.components .form-text-input .text-input-label-container {
  margin-bottom: 1px;
}
.components .form-text-input .text-input-label-container .text-input-label {
  color: #333;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 4px 0 0;
  padding: 0 0 5px;
  position: relative;
}
.components .form-text-input input[type=number]::-webkit-inner-spin-button,
.components .form-text-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.components .form-text-input input[type=number] {
  -moz-appearance: textfield;
}
.components .character-count {
  display: block;
  margin-bottom: 5px;
}
@media (min-width: 630px) {
  .components .character-count {
    float: right;
    margin-bottom: 0;
    margin-top: 1px;
  }
}
.components .form-select-input {
  position: relative;
}
.components .form-select-input:before {
  background: linear-gradient(to left, rgb(255, 255, 255) 25px, rgba(255, 255, 255, 0) 50px);
  content: "\f078";
  display: block;
  font-family: "FontAwesome";
  line-height: 28px;
  min-height: 28px;
  padding-left: 50px;
  padding-right: 10px;
  pointer-events: none;
  position: absolute;
  right: 5px;
  text-align: right;
  top: 31px;
  z-index: 2;
}
.components .form-select-input.no-label:before {
  top: 9px;
}
.components .form-select-input select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #cbcbcb;
  bottom: 0;
  color: #666;
  display: block;
  margin: 0;
  padding: 8px;
  width: 100%;
}
.components .form-radio-binary {
  line-height: 1;
}
@media (min-width: 480px) {
  .components .form-radio-binary {
    line-height: 30px;
  }
}
.components .form-radio-binary .direct-deposit-radio-group {
  line-height: 1em;
}
.components .form-radio-binary .paper-checks-confirmation {
  display: none;
}
.components .form-radio-binary .radio-option {
  display: block;
  margin-bottom: 4px;
}
@media (min-width: 480px) {
  .components .form-radio-binary .radio-option {
    margin-bottom: 0;
  }
}
.components .form-radio-binary .radio-option input[type=radio] {
  margin: 0 2px 0 10px;
}
.components .form-radio-binary .radio-option label {
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.components .form-radio-vertical ul {
  list-style-type: none;
  margin: 0 0 0 16px;
  padding: 0;
}
.components .form-radio-vertical ul li input[type=radio] {
  margin-right: 10px;
}
.components .form-multi-select {
  position: relative;
}
.components .form-multi-select label {
  margin: 0;
  padding: 0;
}
.components .form-flexbox-custom-checkbox {
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.components .form-flexbox-custom-checkbox.residency {
  -webkit-flex-direction: row;
  -webkit-justify-content: flex-start;
  align-items: center;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: static;
}
.components .form-flexbox-custom-checkbox.paper-checks-confirmation .checkbox-control {
  margin-top: -27px;
}
.components .form-flexbox-custom-checkbox .checkbox-control {
  display: inline;
  margin-top: -19px;
  position: absolute;
  top: 50%;
  width: 38px;
}
.components .form-flexbox-custom-checkbox .checkbox-control.residency {
  display: block;
  height: 65px;
  line-height: 1;
  margin: 0 12px 0 0;
  padding: 0;
  position: relative;
  top: 0;
}
.components .form-flexbox-custom-checkbox .checkbox-control label {
  background-color: #fff;
  border: 1px solid #cbcbcb;
  cursor: pointer;
  height: 38px;
  left: 0;
  position: absolute;
  width: 38px;
}
.components .form-flexbox-custom-checkbox .checkbox-control label.residency {
  line-height: 1;
  margin: 13px 0 0;
  padding: 0;
}
.components .form-flexbox-custom-checkbox .checkbox-control label::after {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background: transparent;
  border: 3px solid #333;
  border-right: 0;
  border-top: 0;
  content: "";
  height: 12px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 9px;
  transform: rotate(-45deg);
  width: 25px;
}
.components .form-flexbox-custom-checkbox .checkbox-control input[type=checkbox] {
  opacity: 0;
}
.components .form-flexbox-custom-checkbox .checkbox-control input[type=checkbox]:checked + label::after {
  opacity: 1;
}
.components .form-flexbox-custom-checkbox .checkbox-control input[type=checkbox]:focus + label {
  box-shadow: 0 0 5px 2px #4d90fe;
}
.components .form-flexbox-custom-checkbox.small {
  min-height: 32px;
}
.components .form-flexbox-custom-checkbox.small .checkbox-control {
  width: 19px;
}
.components .form-flexbox-custom-checkbox.small .checkbox-control label {
  height: 19px;
  top: 10px;
  width: 19px;
}
.components .form-flexbox-custom-checkbox.small .checkbox-control label::after {
  height: 6px;
  left: 3px;
  top: 4px;
  width: 12px;
}
.components .form-text-area .textarea-label-container {
  margin-bottom: 1px;
}
.components .form-text-area textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  color: #666;
  margin-bottom: -5px;
  min-height: 200px;
  padding: 10px;
  width: 100%;
}
.components .form-flexbox-radio-gallery {
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: center;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 15px;
  overflow: hidden;
}
.components .form-flexbox-radio-gallery .thumbnail {
  -webkit-flex-basis: calc(50% - 8px);
  -webkit-flex-grow: 0;
  -webkit-flex-shrink: 1;
  border: 0;
  border-radius: 0;
  flex-basis: calc(50% - 8px);
  flex-grow: 0;
  flex-shrink: 1;
  height: auto;
  margin-bottom: 0;
  padding: 4px;
  position: relative;
}
@media (min-width: 630px) {
  .components .form-flexbox-radio-gallery .thumbnail {
    flex-basis: calc(25% - 8px);
  }
}
.components .form-flexbox-radio-gallery .thumbnail input[type=radio] {
  border: 0;
  left: 10px;
  outline: 0;
  position: absolute;
  top: 10px;
}
.components .form-flexbox-radio-gallery .thumbnail input[type=radio]:checked + label img {
  border: 2px solid #30a039;
}
.components .form-flexbox-radio-gallery .thumbnail input[type=radio]:checked + label::after {
  opacity: 1;
}
.components .form-flexbox-radio-gallery .thumbnail label {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
}
.components .form-flexbox-radio-gallery .thumbnail label::after {
  background-color: #71df54;
  background-image: linear-gradient(#71df54, #30a039);
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  height: 23px;
  left: 2px;
  opacity: 0;
  padding-left: 4px;
  padding-top: 1px;
  position: absolute;
  top: 2px;
  width: 23px;
}
.components .form-flexbox-radio-gallery .thumbnail label img {
  border: 2px solid rgba(255, 255, 255, 0);
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.modal-dialog img {
  max-width: 100%;
}

.modal-header button.close span {
  font-size: 30px;
}

.modal-title {
  display: inline;
}

span.glyphicon.glyphicon-menu-right {
  font-size: 14px;
  font-weight: 600;
}

span.load-anim {
  display: none;
}

button[disabled] {
  background: #ccc;
}
button[disabled]:hover {
  background: #ccc;
}
button[disabled] span.chevron {
  display: none;
}
button[disabled] span.load-anim {
  display: inline-block;
}

.display-none {
  display: none;
}

.checkbox-list {
  border: 2px inset #cbcbcb;
  list-style: none;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  padding-left: 0;
  text-align: left;
}
@media (min-width: 630px) {
  .checkbox-list {
    max-height: 500px;
  }
}
.checkbox-list.columned {
  border-style: none;
  column-count: 2;
  max-height: unset;
  overflow: auto;
}
@media (min-width: 630px) {
  .checkbox-list.columned {
    column-count: 3;
  }
}
.checkbox-list label {
  display: inline;
  font-weight: normal;
}

.help-block.with-errors {
  color: #a94442;
}

.modal--video .modal--video--player {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 25px;
  position: relative;
}
.modal--video .modal--video--player iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.take-advantage-row {
  background-color: #1390df;
  background-image: -webkit-linear-gradient(top, #1390df, #0479ca);
  background-image: linear-gradient(to bottom, #1390df, #0479ca);
  padding-bottom: 50px;
  padding-top: 48px;
  text-align: center;
}
@media (min-width: 768px) {
  .take-advantage-row {
    padding-bottom: 85px;
  }
}
.take-advantage-row h2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  margin: 0 0 8px;
}
@media (min-width: 768px) {
  .take-advantage-row h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
.take-advantage-row p {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 0 20px;
}
.take-advantage-row div.link-as-button-container {
  text-align: center;
}

.delete-button {
  cursor: pointer;
}

.number-table {
  border-collapse: separate;
  border-spacing: 5px;
  width: 100%;
}
.number-table tr, .number-table td {
  vertical-align: top;
}
.number-table th {
  font-weight: normal;
}
.number-table .delete-button {
  position: relative;
  top: -5px;
}

.date-label {
  margin-bottom: 5px;
}

.url {
  z-index: -1;
  opacity: 0;
  position: absolute !important;
  top: 0;
  left: 0;
  height: 0;
}

a.external::after {
  /* https://fontawesome.com/icons/arrow-up-right-from-square?f=classic&s=regular */
  content: "\f08e";
  font-family: "FontAwesome";
  padding-left: 5px;
}

.debug {
  background-color: #f2f2f2;
  border: 1px solid #313336;
  margin: 20px;
  padding: 50px;
}
.debug::before {
  content: "DEBUG";
  display: block;
  font-size: large;
  margin-bottom: 20px;
}
.debug td,
.debug th {
  background-color: #fff;
  border: 2px solid #f2f2f2;
  padding: 5px;
}

.register-page-content {
  padding-top: 40px;
  text-align: left;
}
@media (min-width: 768px) {
  .register-page-content {
    max-width: none;
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.register-page-content .register-main {
  margin: 0 auto;
  max-width: 400px;
}
@media (min-width: 768px) {
  .register-page-content .register-main {
    border-right: 1px solid #cbcbcb;
    float: left;
    max-width: 50%;
    padding-right: 6%;
  }
}
@media (min-width: 1199px) {
  .register-page-content .register-main {
    padding-right: 8%;
  }
}
.register-page-content .register-main .instructions {
  color: #313336;
  text-align: left;
}
.register-page-content .register-main button {
  margin-top: 5px;
}
.register-page-content .register-have-questions {
  background-color: #f2f2f2;
  padding: 35px 15px 50px;
  margin: 40px -15px 0;
}
@media (min-width: 768px) {
  .register-page-content .register-have-questions {
    background-color: transparent;
    float: right;
    margin: 0;
    max-width: 44%;
    padding: 0;
    width: 100%;
  }
}
@media (min-width: 1199px) {
  .register-page-content .register-have-questions {
    max-width: 42%;
  }
}
.register-page-content .register-have-questions .have-questions-inner {
  margin: 0 auto;
  max-width: 400px;
}
@media (min-width: 768px) {
  .register-page-content .register-have-questions .have-questions-inner {
    margin: 0;
    max-width: none;
  }
}
.register-page-content .register-have-questions .have-questions-inner img {
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .register-page-content .register-have-questions .have-questions-inner img {
    max-width: 370px;
  }
}
.register-page-content .register-have-questions .have-questions-inner h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .register-page-content .register-have-questions .have-questions-inner h3 {
    font-size: 40px;
  }
}
.register-page-content .register-have-questions .have-questions-inner h4, .register-page-content .register-have-questions .have-questions-inner a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.44;
  margin: 20px 0;
}

.back-button {
  display: block;
  padding-top: 10px;
}

.flex-container {
  -webkit-justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.flex-container .day {
  width: 30%;
}
.flex-container .month {
  width: 30%;
}
.flex-container .year {
  width: 30%;
}

.small-gray-horz-rule {
  border-bottom: 1px solid #ccc;
  display: block;
  margin-bottom: 35px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 35px;
}
@media (min-width: 768px) {
  .small-gray-horz-rule {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#select-main #select-filter {
  background-color: #f2f2f2;
  border: 1px solid #cbcbcb;
  margin-bottom: 0;
  margin-top: 25px;
  padding: 20px;
  text-align: left;
}
@media (min-width: 768px) {
  #select-main #select-filter {
    margin-top: 38px;
  }
}
#select-main #select-filter h2 {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 10px;
  text-transform: uppercase;
}
#select-main .select-result {
  border: 1px solid #cbcbcb;
  border-top: 0;
  padding: 20px;
  text-align: left;
}
@media (min-width: 768px) {
  #select-main .select-result {
    min-height: 100px;
    padding: 20px 20px 0;
  }
}
#select-main .select-result .result-details {
  margin-bottom: 14px;
}
#select-main .select-result .result-details h2 {
  color: #333;
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 4px;
}
#select-main .select-result .result-details p {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 2px;
}
#select-main .select-result .result-details p span.city-state {
  color: #333;
  font-weight: 600;
}
#select-main .select-result .result-button {
  margin-bottom: 0;
  text-align: center;
}
#select-main #dont-see-profile-text-link {
  margin-top: 40px;
}
#select-main .use-pointer-cursor {
  cursor: pointer;
}

#edit-main {
  text-align: left;
}
#edit-main .languages-outer {
  margin-bottom: 15px;
}
@media (min-width: 630px) {
  #edit-main .languages-outer {
    margin-bottom: 0;
    margin-top: 10px;
  }
}
#edit-main .languages-spoken {
  margin-bottom: 10px;
  margin-top: 5px;
}
@media (min-width: 630px) {
  #edit-main .languages-spoken {
    margin-bottom: 0;
    margin-top: 0;
  }
}
#edit-main .languages-select-results {
  margin-top: 0;
}
@media (min-width: 630px) {
  #edit-main .languages-select-results {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  #edit-main .languages-select-results {
    margin-top: 0;
  }
}

#account-main .container {
  text-align: left;
}
#account-main .container p {
  font-weight: 600;
}
#account-main .container #terms-group p {
  font-weight: 400;
}
#account-main #example-photo {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
#account-main #example-photo img {
  margin-bottom: 20px;
  max-width: 100%;
}
#account-main #bio-pic-upload .bio-pic-instructions {
  margin-bottom: 50px;
}
#account-main #bio-pic-upload h3 {
  color: #333;
  font-size: 18px;
}
#account-main #bio-pic-upload #photo-upload-placeholder img {
  cursor: pointer;
  display: block;
  margin: 0 auto 12px;
  max-width: 100%;
  width: 200px;
}
#account-main #bio-pic-upload #bio-pic-instructions {
  color: #666;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
}
@media (min-width: 630px) {
  #account-main #bio-pic-upload #bio-pic-instructions {
    text-align: left;
  }
}
#account-main #bio-pic-upload #bio-pic-instructions a#sample-profile {
  display: block;
}
#account-main #bio-pic-upload #upload-file-button-container {
  margin: 10px 0;
  text-align: center;
}
#account-main #bio-pic-upload #upload-file-button-container #provider_photo {
  display: none;
}
#account-main #bio-pic-upload #upload-error {
  color: #a94442;
}
#account-main #welcome-msg-instructions {
  margin-bottom: 50px;
}
#account-main #welcome-msg-instructions h3 {
  color: #333;
  font-size: 1em;
  font-weight: 600;
}
#account-main #welcome-msg {
  margin-bottom: 50px;
}
#account-main #welcome-msg p span {
  display: block;
}
@media (min-width: 630px) {
  #account-main #welcome-msg p span {
    float: right;
  }
}
#account-main #terms-group {
  margin-top: 10px;
}
#account-main .signature-row p {
  font-weight: 400;
  margin: 10px 0 20px;
}

#paid-main, #direct-main {
  padding-top: 25px;
  text-align: left;
}
#paid-main .container, #direct-main .container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  #paid-main .container, #direct-main .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#paid-main .container .birthday p, #direct-main .container .birthday p {
  margin-bottom: 6px;
}
#paid-main .container .birthday .flex-container, #direct-main .container .birthday .flex-container {
  -webkit-justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
#paid-main .container .birthday .flex-container .month, #direct-main .container .birthday .flex-container .month {
  display: -webkit-flex-item;
  display: flex-item;
  height: auto;
  margin-bottom: 0;
  width: 45%;
}
#paid-main .container .birthday .flex-container .day,
#paid-main .container .birthday .flex-container .year, #direct-main .container .birthday .flex-container .day,
#direct-main .container .birthday .flex-container .year {
  display: -webkit-flex-item;
  display: flex-item;
  height: auto;
  margin-bottom: 0;
  width: 25%;
}
#paid-main .modal-body, #direct-main .modal-body {
  text-align: center;
}
#paid-main .modal-body img, #direct-main .modal-body img {
  max-width: 792px;
  width: 100%;
}
#paid-main #tax-info-new-address, #paid-main #bank-info-new-address, #direct-main #tax-info-new-address, #direct-main #bank-info-new-address {
  display: none;
}
#paid-main #tax-section-intro, #direct-main #tax-section-intro {
  margin-bottom: 15px;
}
#paid-main .gray-well, #direct-main .gray-well {
  margin-top: 25px;
}
@media (min-width: 630px) {
  #paid-main .gray-well, #direct-main .gray-well {
    margin-top: 50px;
  }
}
#paid-main #certification, #direct-main #certification {
  text-align: left;
}
@media (min-width: 768px) {
  #paid-main #certification .left-side, #direct-main #certification .left-side {
    padding-left: 0;
  }
  #paid-main #certification .right-side, #direct-main #certification .right-side {
    padding-right: 0;
  }
}
#paid-main .review-section p, #direct-main .review-section p {
  color: #666;
  margin-bottom: 21px;
}
@media (min-width: 630px) {
  #paid-main .review-section p, #direct-main .review-section p {
    text-align: center;
  }
}

#lab-coat-modal .modal-body {
  text-align: left;
}

#check-image-container {
  text-align: center;
}

.policy {
  margin: 0 auto;
  max-width: 1024px;
  text-align: left;
}
@media (min-width: 768px) {
  .policy .modal-header,
  .policy .modal-body {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.policy .modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.policy .modal-body h1,
.policy .modal-body h2,
.policy .modal-body h3 {
  margin: 10px 0;
  text-align: left;
}
.policy .modal-body h1 {
  font-size: 1.75em;
}
.policy .modal-body h2 {
  font-size: 1.5em;
}
.policy .modal-body h3 {
  font-size: 1.25em;
}
.policy h2 {
  font-size: 24px;
  margin: 5px 0;
  text-align: center;
}
.policy .table-row-first {
  margin-top: 40px;
}
.policy .table-row {
  margin-bottom: 20px;
}
.policy .table-row h3 {
  font-size: 16px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .policy .table-row h3 {
    margin-top: 0;
    padding-right: 20px;
  }
}
.policy .table-row-last {
  margin-bottom: 40px;
}
@media (max-width: 630px) {
  .policy .break {
    word-break: break-all;
  }
}
.policy .center {
  text-align: center;
}
.policy .indent {
  text-indent: 20px;
}
.policy .no-bot-margin {
  margin-bottom: 0;
}
.policy .list-bot-margin {
  margin-bottom: 10px;
}
.policy ul {
  margin: 0;
}
.policy ol {
  margin-bottom: 10px;
}
.policy ol li {
  margin-bottom: 8px;
}
.policy ol li:last-child {
  margin-bottom: 0;
}
.policy ol.parens-num {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
  text-indent: -22px;
}
.policy ol.parens-num > li {
  counter-increment: item;
  list-style: none inside;
  margin-left: 20px;
  padding-left: 20px;
}
.policy ol.parens-num > li::before {
  content: '(" counter(item) ")';
  padding-right: 0.5em;
}

#verify-main {
  padding-top: 25px;
  text-align: left;
}
#verify-main .container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  #verify-main .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#verify-main .gray-well {
  text-align: center;
}
#verify-main .security-question-group {
  margin-bottom: 18px;
}
#verify-main .verification-choices {
  list-style: none;
}
#verify-main .verification-choices label {
  font-weight: normal;
}

#app-register-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step1_RegisterToPractice.jpg");
}
@media (min-width: 1564px) {
  #app-register-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step1_RegisterToPractice.jpg");
  }
}

#app-select-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step2_SelectYourProfile.jpg");
}
@media (min-width: 1564px) {
  #app-select-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step2_SelectYourProfile.jpg");
  }
}

#app-edit-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step3_EditYourProfile.jpg");
}
@media (min-width: 1564px) {
  #app-edit-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step3_EditYourProfile.jpg");
  }
}

#app-customize-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step4_CustomizeYourPractice.jpg");
}
@media (min-width: 1564px) {
  #app-customize-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step4_CustomizeYourPractice.jpg");
  }
}

#app-paid-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step5_GetPaid.jpg");
}
@media (min-width: 1564px) {
  #app-paid-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step5_GetPaid.jpg");
  }
}

#app-verify-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step6_IDVerification.jpg");
}
@media (min-width: 1564px) {
  #app-verify-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step6_IDVerification.jpg");
  }
}

#app-account-hero {
  background-image: url("../../../../pixel/assets/images/3128x880_Step_CreateYourAccount.jpg");
}
@media (min-width: 1564px) {
  #app-account-hero {
    background-image: url("../../../../pixel/assets/images/4000x880_Step_CreateYourAccount.jpg");
  }
}

.error {
  color: #a94442;
}

.notice {
  border: 2px solid #0469bd;
  margin-top: 40px;
  min-height: 104px;
  padding: 20px 20px 20px 100px;
}
.notice.error {
  border-color: #a94442;
}
.notice .icon-AW_Information::before {
  color: #96a0ac;
  content: "\e90f";
  font-size: 63px;
  left: 42px;
  line-height: 63px;
  position: absolute;
}

.full .notice .icon-AW_Information::before {
  left: 36px;
}
@media (min-width: 630px) {
  .full .notice .icon-AW_Information::before {
    left: 23px;
  }
}