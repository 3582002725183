@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,700');
@import 'base';
@import 'buttons';
@import 'header';

$form-element-label-text-color: $color-text-title;
$form-element-text-color: $color-text-default;
$form-element-margin-bottom: 12px;

$color-focus: #4d90fe;
$color-footer: #383b3f;
$color-gray-circle: #c9c9c9;
$color-green-button-border: #11722b;
$color-green-button-gradient: #218518;
$color-label-border: rgba(255, 255, 255, 0);
$color-progress-background: #f5f5f5;
$color-progress-bar: #dbdbdb;
$color-tooltip-border: rgba(0, 0, 0, .25);

$hero-message-vertical-offset: 27px;

// Set the max-width of the container to 960px. This overrides the default
// largest container breakpoint size without having to build a custom version
// of Bootstrap. Ideally BS would be configured to the specific site
// requirements. Since this wasn't addressed until the end this is the path
// of least resistence and doesn't hack anything so side-effects should be
// non-existent.
.container {
  max-width: 960px;
}

.modal {
  z-index: 99999;
}

.center {
  text-align: center;
}

.clear {
  clear: both;
}

.error-page {
  margin: 0 auto;
  max-width: 500px;
  padding: 100px 30px;

  h1 {
    font-size: 100px;
  }

  @media (min-width: $container-sm) {
    padding: 200px 30px;
  }
}

.page-submit-button {
  margin-top: 50px;
}

// AW font
@font-face {
  // sass-lint:disable-block no-duplicate-properties
  // sass-lint:disable-block indentation
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('#{$pixel-path-fonts}/icomoon.eot?3rvdx3');
  src:
    url('#{$pixel-path-fonts}/icomoon.eot?3rvdx3#iefix') format('embedded-opentype'),
    url('#{$pixel-path-fonts}/icomoon.ttf?3rvdx3') format('truetype'),
    url('#{$pixel-path-fonts}/icomoon.woff?3rvdx3') format('woff'),
    url('#{$pixel-path-fonts}/icomoon.svg?3rvdx3#icomoon') format('svg');
}

[class^='icon-AW'],
[class*=' icon-AW'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // use !important to prevent issues with browser extensions that change fonts
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

// prevent inputs from zooming in on mobile devices:
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select,
select:focus,
textarea {
  font-size: 16px;

  @media (min-width: $container-sm) {
    font-size: 14px;
  }
}

// Style disabled inputs
input[readonly] {
  color: $color-medium-gray !important;
  cursor: not-allowed;
}

// Bootstrap tooltip override styles:
.tooltip > .tooltip-inner {
  -moz-box-shadow: 5px 5px 30px 0 $color-tooltip-border;
  -webkit-box-shadow: 5px 5px 30px 0 $color-tooltip-border;
  background-color: $color-light-gray;
  border: 1px solid $color-gray-border;
  box-shadow: 5px 5px 30px 0 $color-tooltip-border;
  color: $color-text-title;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 15px;
  max-width: none;
  padding: 20px;
  text-align: left;
  width: 290px;
}

.tooltip.top > .tooltip-arrow {
  border-top: 5px solid $color-gray-border;
}

.tooltip.in {opacity: 1 !important;}

// -----------------------------

body {
  color: $color-text-default;
  counter-reset: step;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: $color-science-blue;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: $color-dodger-blue;
  }
}

// -----------------------------

.hero {
  background-image: url('#{$pixel-path-images}/img_wood_3128x596.jpg');
  background-position: top center;
  background-size: cover;
  color: $color-white;
  height: 237px;
  overflow: hidden;
  padding-top: 64px;
  position: relative;
  text-align: center;

  @media (min-width: $container-md) {
    height: 300px;
  }

  .hero-inner {
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    left: 50%;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: calc(50% + #{$hero-message-vertical-offset});
    transform: translate(-50%, -50%);
    width: 100%;

    h1 {
      -webkit-font-smoothing: antialiased;
      font-size: 34px;
      font-weight: 300;
      line-height: 1.2;
      margin: 8px 0 0;
      width: auto;

      @media (min-width: $container-md) {
        font-size: 60px;
        margin-bottom: 0;
        padding: 0;
        width: auto;
      }
    }

    img.icon {
      height: auto;
      margin-top: 10px;
      width: 100px;

      &.done {
        width: 71px;
      }

      @media (min-width: $container-md) {
        width: 125px;

        &.done {
          width: 89px;
        }
      }
    }
  }

  // triangle mask


  &::before {
    border-bottom: 20px solid $color-scampi;
    border-left: 20px solid $color-scampi;
    border-right: 20px solid transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    width: 50%;
  }

  &::after {
    border-bottom: 20px solid $color-scampi;
    border-left: 20px solid transparent;
    border-right: 20px solid $color-scampi;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    width: 50%;
  }

}

.hero-notchless {
  // no triangle mask
  &::before,
  &::after {
    border: 0;
  }
}

// -----------------------------

.full-width-gray {
  background-color: $color-light-gray;
}

.constrain-width-on-mobile-to-620 {
  margin: 0 auto;
  max-width: 328px;

  @media (min-width: $container-sm) {
    max-width: 656px;
  }

  @media (min-width: $container-md) {
    max-width: 100%;
  }
}

// -----------------------------

#footer {
  background: $color-footer;
  color: $color-white;
  padding: 10px;
  text-align: center;

  .legal {
    text-transform: uppercase;
  }

  img {
    height: auto;
    margin-bottom: 15px;
    margin-top: 28px;
    width: 200px;

    @media (min-width: $container-sm) {
      width: 300px;
    }
  }

  p {
    color: $color-gray;
    font-size: 11px;
    line-height: 18px;
    margin: 0;
    padding-bottom: 16px;

    @media (min-width: $container-sm) {
      font-size: 12px;
      line-height: 21px;
    }

    a {
      color: $color-gray;
      cursor: pointer;
      margin-left: 14px;
      text-decoration: underline;

      &#terms-of-use {
        margin-right: 14px;
      }
    }
  }
}

// -----------------------------

// defines the outermost styles for each page
.page {
  -webkit-font-smoothing: antialiased;
  background-color: $color-white;
  text-align: center;

  #main-page-container {
    padding: 32px 15px 80px;

    @media (min-width: $container-md) {
      padding: 32px 0 80px;
    }

    &.no-padding {
      padding: 0;
    }

    &.follows-progress {
      padding-top: 20px;
    }

    p {
      color: $color-text-title;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// -----------------------------

#progress {
  background-color: $color-progress-background;
  overflow-x: hidden;
  padding: 22px 0 44px;

  @media (min-width: $container-sm) {
    margin-bottom: 25px;
    padding: 40px 0 84px;
  }

  .bar {
    background-color: $color-progress-bar;
    display: flex;
    height: 5px;
    justify-content: space-between;
    margin: 0 5px;

    @media (min-width: $container-sm) {
      height: 12px;
      margin: 0 15px;
    }

    @media (min-width: $container-lg) {
      margin: 0 65px;
    }

    .step-group {
      cursor: default;
      display: block;
      margin-top: -12px;
      position: relative;

      @media (min-width: $container-sm) {
        margin-top: -19px;
      }

      &[href] {
        cursor: pointer;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      div.circle-num {
        background-color: $color-slate-gray;
        border-radius: 100%;
        color: $color-white;
        font-size: 15px;
        font-weight: 600;
        height: 30px;
        line-height: 30px;
        width: 30px;

        &::before {
          content: counter(step);
          counter-increment: step;
        }

        @media (min-width: $container-sm) {
          font-size: 27px;
          height: 54px;
          line-height: 54px;
          width: 54px;
        }
      }

      div.circle-done {
        background-color: $color-pastel-green;
        background-image: linear-gradient($color-pastel-green, $color-forest-green);
        border-radius: 100%;
        color: $color-white;
        display: none;
        font-size: 15px;
        font-weight: 600;
        height: 30px;
        line-height: 30px;
        width: 30px;

        &::before {
          counter-increment: step;
        }

        @media (min-width: $container-sm) {
          font-size: 27px;
          height: 54px;
          line-height: 54px;
          width: 54px;
        }
      }

      .step-desc {
        color: $color-text-default;
        font-size: 11px;
        left: 50%;
        line-height: 1;
        margin: 5px 0 0;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        white-space: normal;
        width: 45px;

        @media (min-width: $container-sm) {
          font-size: 14px;
          margin: 10px 0 0;
          width: 100px;
        }

        @media (min-width: $container-lg) {
          width: 120px;
        }
      }

      .step-desc.hide-on-mobile {
        display: none;

        @media (min-width: $container-sm) {
          display: block;
        }
      }

      .step-desc.hide-on-desktop {
        @media (min-width: $container-sm) {
          display: none;
        }
      }
    }

    .active {
      .circle-num {
        background-image: linear-gradient($color-dodger-blue, $color-science-blue);
      }

      .step-desc {
        color: $color-text-title;
        font-weight: 600;
      }
    }

    .completed {
      cursor: pointer;

      div.circle-num {
        display: none;
      }

      div.circle-done {
        display: inline-block;
      }
    }
  }
}

// -----------------------------

// the page's headline (if any) and paragraph
// introduction text
.page-intro {
  overflow-x: hidden;
  text-align: center;

  &.purple-background {
    background-color: $color-scampi;

    h2 {
      color: $color-white;
      font-size: 14px;
      line-height: 20px;
      padding: 15px 0 30px;

      @media (min-width: $container-md) {
        font-size: 20px;
        line-height: 30px;
        padding: 45px 0 50px;
      }
    }
  }

  h2 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 10px;

    @media (min-width: $container-sm) {
      font-size: 30px;
      margin-bottom: 12px;
    }
  }

  p {
    margin: 0;
  }
}

// -----------------------------

// for sections within a page with full-width underline
// rule. Section title may be preceded with a circled
// number
.section-header {
  margin-top: 28px;

  @media (min-width: $container-sm) {
    margin-top: 25px;
  }

  h2 {
    border-bottom: 1px solid $color-horiz-divider;
    color: $color-text-title;
    font-size: 19px;
    margin: 0 0 15px;
    padding-bottom: 8px;

    @media (min-width: $container-sm) {
      font-size: 25px;
      padding-bottom: 16px;
    }

    span {
      font-size: 15px;

      @media (min-width: $container-sm) {
        font-size: 25px;
      }
    }

    span.gray-circle-num {
      background-color: $color-gray-circle;
      border-radius: 100%;
      color: $color-white;
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      height: 24px;
      line-height: 22px;
      margin-right: 6px;
      margin-top: -4px;
      text-align: center;
      vertical-align: middle;
      width: 24px;

      @media (min-width: $container-sm) {
        font-size: 26px;
        height: 40px;
        line-height: 38px;
        margin-right: 10px;
        width: 40px;
      }
    }
  }
}


// -----------------------------

// the line the precedes the button at the bottom of each
// page
.gray-horz-rule {
  @media (min-width: $container-md) {
    border-bottom: 1px solid $color-horiz-divider;
    display: block;
    margin: 30px 0 40px;
  }
}

// -----------------------------

// the gray boxed areas
.gray-well {
  background-color: $color-light-gray;
  border: 1px solid $color-gray-border;
  margin-bottom: 26px;
  padding: 20px;
  text-align: center;

  @media (min-width: $container-sm) {
    margin-bottom: 36px;
    margin-top: 38px;
    text-align: left;
  }

  ol {
    margin-bottom: 30px;
    padding: 0 0 0 20px;
    text-align: left;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// -----------------------------

// re-usable form elements:
.components {
  font-family: 'Open Sans', sans-serif;
  text-align: left;

  // positioning utils:
  .center {
    text-align: center;
  }

  .center-mobile-only {
    text-align: center;

    @media (min-width: $container-md) {
      text-align: left;
    }
  }

  // -----------------------------

  // form-element class
  .form-element {
    border: 1px solid transparent;
    box-sizing: border-box;
    margin-bottom: $form-element-margin-bottom;
    min-height: 65px;

    label,
    .radio-label,
    .checkbox-label,
    .checkbox-label-flexbox,
    .textarea-label {
      color: $form-element-label-text-color;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      margin: 0 4px 0 0;
      padding: 0 0 5px;
      position: relative;

      .question-icon {
        background-image: url('#{$pixel-path-images}/icon_tiny_question_mark_37x37.gif');
        background-size: 18px 18px;
        cursor: pointer;
        display: inline-block;
        height: 18px;
        margin-bottom: -10px;
        margin-left: 4px;
        position: relative;
        top: -6px;
        width: 18px;
      }

      .text-link {
        font-size: 12px;
        font-weight: 600;
        margin-left: 4px;
      }
    }

    .checkbox-label {
      cursor: pointer;
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    .checkbox-label-flexbox {
      cursor: pointer;
      display: inline-block;
      outline: none;
      padding-left: 50px;
      padding-top: 4px;

      &.residency {
        margin: 0;
        padding: 0;
        position: static;
      }

      span.help-block,
      ul.list-unstyled {
        margin-bottom: 0 !important;
      }

      a {
        outline: none;
      }
    }

    &.no-label {
      margin-bottom: 4px;
      min-height: 40px;
    }

    &.no-label.form-select-input {
      &::after {
        top: 9px;
      }
    }

    &.no-label.form-text-input {
      &::after {
        top: 9px;
      }

      .other-credential-or-specialty {
        color: $color-dark-gray;
        padding-top: 10px;
      }
    }

    &.no-vert-padding {
      min-height: auto;
      padding-top: 0;
    }
  }

  // -----------------------------

  // button class
  .button {
    a,
    label {
      border: 0;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-white;
      cursor: pointer;
      display: inline-block;
      font-size: 21px;
      font-weight: normal;
      height: 50px;
      line-height: 26px;
      max-width: 300px;
      padding: 12px;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;

      &:hover {
        color: $color-white;
      }
    }

    &.button-green a,
    &.button-green label {
      background-color: $color-pastel-green;
      background-image: linear-gradient($color-pastel-green, $color-forest-green);

      &:hover {
        background-color: $color-forest-green;
        background-image: linear-gradient($color-forest-green, $color-pastel-green);
      }
    }

    &.button-blue a,
    &.button-blue label {
      background-color: $color-dodger-blue;
      background-image: linear-gradient($color-dodger-blue, $color-science-blue);

      &:hover {
        background-color: $color-science-blue;
        background-image: linear-gradient($color-science-blue, $color-dodger-blue);
      }
    }
  }

  // -----------------------------

  // file inputs
  input[type='file'] {
    visibility: hidden;
  }

  label.link {
    color: $color-dodger-blue;
    cursor: pointer;
    font-weight: normal;
  }

  // -----------------------------

  // large-text-link class
  .large-text-link {
    padding: 0;

    a {
      font-size: 21px;
      font-weight: normal;
      line-height: 26px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span.glyphicon {
      font-size: 14px;
      font-weight: 600;
    }
  }

  // -----------------------------

  // form-text-input class
  .form-text-input {
    position: relative;

    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='date'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid $color-gray-border;
      border-radius: 0;
      bottom: 0;
      color: $form-element-text-color;
      display: block;
      height: 38px;
      margin: 0 !important;
      padding: 5px 10px;
      position: relative;
      width: 100%;
    }

    &.financial-info-container {
      display: none;
    }

    .dollar-sign-wrapper {
      position: relative;

      &::before {
        color: $form-element-text-color;
        content: '$';
        left: 12px;
        position: absolute;
        top: 9px;
        z-index: 1;
      }

      input[type='text'] {
        padding-left: 19px !important;
      }
    }

    .text-input-label-container {
      margin-bottom: 1px;

      .text-input-label {
        color: $form-element-label-text-color;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0 4px 0 0;
        padding: 0 0 5px;
        position: relative;
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .character-count {
    display: block;
    margin-bottom: 5px;

    @media (min-width: $container-sm) {
      float: right;
      margin-bottom: 0;
      margin-top: 1px;
    }
  }

  // -----------------------------

  // form-select-input class
  .form-select-input {
    position: relative;

    &:before {
      background: linear-gradient(to left, rgba(255,255,255,1) 25px,rgba(255,255,255,0) 50px);
      content: "\f078";
      display: block;
      font-family: "FontAwesome";
      line-height: 28px;
      min-height: 28px;
      padding-left: 50px;
      padding-right: 10px;
      pointer-events: none;
      position: absolute;
      right: 5px;
      text-align: right;
      top: 31px;
      z-index: 2;
    }

    &.no-label:before {
      top: 9px;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: $color-white;
      border-radius: 0;
      border: 1px solid $color-gray-border;
      bottom: 0;
      color: $form-element-text-color;
      display: block;
      margin: 0;
      padding: 8px;
      width: 100%;
    }
  }

  // -----------------------------

  // form-radio-binary class
  .form-radio-binary {
    line-height: 1;

    @media (min-width: $container-xsm) {
      line-height: 30px;
    }

    .direct-deposit-radio-group {
      line-height: 1em;
    }

    .paper-checks-confirmation {
      display: none;
    }

    .radio-option {
      display: block;
      margin-bottom: 4px;

      @media (min-width: $container-xsm) {
        margin-bottom: 0;
      }

      input[type='radio'] {
        margin: 0 2px 0 10px;
      }

      label {
        cursor: pointer;
        margin: 0;
        padding: 0;
      }
    }
  }

  // -----------------------------

  // form-radio-vertical class
  .form-radio-vertical {
    ul {
      list-style-type: none;
      margin: 0 0 0 16px;
      padding: 0;

      li {
        input[type='radio'] {
          margin-right: 10px;
        }
      }
    }
  }

  // -----------------------------

  // form-multi-select class
  .form-multi-select {
    position: relative;

    label {
      margin: 0;
      padding: 0;
    }
  }

  // -----------------------------

  // form-flexbox-custom-checkbox class
  .form-flexbox-custom-checkbox {
    -webkit-flex-direction: column;
    -webkit-justify-content: center;
    display: -webkit-flex; // sass-lint:disable-line no-duplicate-properties
    display: flex;         // sass-lint:disable-line no-duplicate-properties
    flex-direction: column;
    justify-content: center;
    position: relative;

    &.residency {
      -webkit-flex-direction: row;
      -webkit-justify-content: flex-start;
      align-items: center;
      display: -ms-flexbox;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      position: static;
    }

    &.paper-checks-confirmation {
      .checkbox-control {
        margin-top: -27px;
      }
    }

    .checkbox-control {
      display: inline;
      margin-top: -19px;
      position: absolute;
      top: 50%;
      width: 38px;

      &.residency {
        display: block;
        height: 65px;
        line-height: 1;
        margin: 0 12px 0 0;
        padding: 0;
        position: relative;
        top: 0;
      }

      label {
        background-color: $color-white;
        border: 1px solid $color-gray-border;
        cursor: pointer;
        height: 38px;
        left: 0;
        position: absolute;
        width: 38px;

        &.residency {
          line-height: 1;
          margin: 13px 0 0;
          padding: 0;
        }

        &::after {
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          background: transparent;
          border: 3px solid $color-text-title;
          border-right: 0;
          border-top: 0;
          content: '';
          height: 12px;
          left: 6px;
          opacity: 0;
          position: absolute;
          top: 9px;
          transform: rotate(-45deg);
          width: 25px;
        }
      }

      input[type='checkbox'] {
        opacity: 0;

        &:checked + label::after {
          opacity: 1;
        }

        &:focus + label {
          // Mimics Chrome's focus style - Unfortunately won't match all browsers
          box-shadow: 0 0 5px 2px $color-focus;
        }
      }
    }

    &.small {
      min-height: 32px;

      .checkbox-control {
        width: 19px;

        label {
          height: 19px;
          top: 10px;
          width: 19px;

          &::after {
            height: 6px;
            left: 3px;
            top: 4px;
            width: 12px;
          }
        }
      }
    }
  }

  // -----------------------------

  // form-text-area class
  .form-text-area {
    .textarea-label-container {
      margin-bottom: 1px;
    }

    textarea {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid $color-gray-border;
      border-radius: 0;
      color: $form-element-text-color;
      margin-bottom: -5px;
      min-height: 200px;
      padding: 10px;
      width: 100%;
    }
  }

  // -----------------------------

  // form-flexbox-radio-gallery class
  .form-flexbox-radio-gallery {
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: center;
    display: -webkit-flex; // sass-lint:disable-line no-duplicate-properties
    display: flex;         // sass-lint:disable-line no-duplicate-properties
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0 15px;
    overflow: hidden;

    .thumbnail {
      -webkit-flex-basis: calc(50% - 8px);
      -webkit-flex-grow: 0;
      -webkit-flex-shrink: 1;
      border: 0;
      border-radius: 0;
      flex-basis: calc(50% - 8px);
      flex-grow: 0;
      flex-shrink: 1;
      height: auto;
      margin-bottom: 0;
      padding: 4px;
      position: relative;

      @media (min-width: $container-sm) {
        flex-basis: calc(25% - 8px);
      }

      input[type='radio'] {
        border: 0;
        left: 10px;
        outline: 0;
        position: absolute;
        top: 10px;

        &:checked+label {
          img {
            border: 2px solid $color-forest-green;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      label {
        cursor: pointer;
        height: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;

        &::after {
          background-color: $color-pastel-green;
          background-image: linear-gradient($color-pastel-green, $color-forest-green);
          color: $color-white;
          content: '\f00c';
          font-family: FontAwesome;
          height: 23px;
          left: 2px;
          opacity: 0;
          padding-left: 4px;
          padding-top: 1px;
          position: absolute;
          top: 2px;
          width: 23px;
        }

        img {
          border: 2px solid $color-label-border;
          height: auto;
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}

// -----------------------------

// Overrides for modals
.modal-dialog {
  img {
    max-width: 100%;
  }
}

.modal-header {
  button.close span {
    font-size: 30px;
  }
}

.modal-title {
  display: inline;
}

// -----------------------------

span.glyphicon.glyphicon-menu-right {
  font-size: 14px;
  font-weight: 600;
}

span.load-anim {
  display: none;
}

button[disabled] {
  background: $color-gray;

  &:hover {
    background: $color-gray;
  }

  span.chevron {
    display: none;
  }

  span.load-anim {
    display: inline-block;
  }
}

.display-none {
  display: none;
}

.checkbox-list {
  border: 2px inset $color-gray-border;
  list-style: none;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  padding-left: 0;
  text-align: left;

  @media (min-width: $container-sm) {
    max-height: 500px;
  }

  &.columned {
    border-style: none;
    column-count: 2;
    max-height: unset;
    overflow: auto;

    @media (min-width: $container-sm) {
      column-count: 3;
    }
  }

  label {
    display: inline;
    font-weight: normal;
  }
}

// -----------------------------

.help-block.with-errors {
  color: $color-error;
}

// modals
.modal--video {
  // https://alistapart.com/article/creating-intrinsic-ratios-for-video
  .modal--video--player {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%; // 16/9 ratio
    padding-top: 25px; // IE6 workaround
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
   }
  }
}

// -----------------------------

.take-advantage-row {
  @include background-gradient(#1390df, #0479ca, vertical);
  padding-bottom: 50px;
  padding-top: 48px;
  text-align: center;

  @media (min-width: $container-md) {
    padding-bottom: 85px;
  }

  h2 {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $color-white;
    font-size: 34px;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 8px;

    @media (min-width: $container-md) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  p {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $color-white;
    font-size: 14px;
    line-height: 1.4;
    margin: 0 0 20px;
  }

  div.link-as-button-container {
    text-align: center;
  }
}


.delete-button {
  cursor: pointer;
}

.number-table {
  border-collapse: separate;
  border-spacing: 5px;
  width: 100%;

  tr, td {
    vertical-align: top;
  }

  th {
    font-weight: normal;
  }

  .delete-button {
    position: relative;
    top: -5px;
  }
}

.date-label {
  margin-bottom: 5px;
}

// -----------------------------

// Class for honeypot trap to prevent spam and hide this from regular users
.url {
  z-index:-1;
  opacity: 0;
  position: absolute !important;
  top: 0;
  left: 0;
  height: 0;
}

a.external {
  &::after {
    /* https://fontawesome.com/icons/arrow-up-right-from-square?f=classic&s=regular */
    content: "\f08e";
    font-family: "FontAwesome";
    padding-left: 5px;
  }
}
