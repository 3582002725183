$env: "production";
@import 'main';

$color-faint-gray: #cbcbcb;
$color-off-white: #f2f2f2;

.debug {
  background-color: $color-light-gray;
  border: 1px solid $color-dark-gray;
  margin: 20px;
  padding: 50px;

  &::before {
    content: 'DEBUG';
    display: block;
    font-size: large;
    margin-bottom: 20px;
  }

  td,
  th {
    background-color: $color-white;
    border: 2px solid $color-light-gray;
    padding: 5px;
  }
}

// -----------------------------

// per-page custom styles:
.register-page-content {
  padding-top: 40px;
  text-align: left;

  @media (min-width: $container-md) {
    max-width: none;
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .register-main {
    margin: 0 auto;
    max-width: 400px;

    @media (min-width: $container-md) {
      border-right: 1px solid $color-faint-gray;
      float: left;
      max-width: 50%;
      padding-right: 6%;
    }

    @media (min-width: $container-lg) {
      padding-right: 8%;
    }

    .instructions {
      color: $color-dark-gray;
      text-align: left;
    }

    button {
      margin-top: 5px;
    }
  }

  .register-have-questions {
    background-color: $color-off-white;
    padding: 35px 15px 50px;
    margin: 40px -15px 0;

    @media (min-width: $container-md) {
      background-color: transparent;
      float: right;
      margin: 0;
      max-width: 44%;
      padding: 0;
      width: 100%;
    }

    @media (min-width: $container-lg) {
      max-width: 42%;
    }

    .have-questions-inner {
      margin: 0 auto;
      max-width: 400px;

      @media (min-width: $container-md) {
        margin: 0;
        max-width: none;
      }

      img {
        height: auto;
        width: 100%;

        @media (min-width: $container-md) {
          max-width: 370px;
        }
      }

      h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 1.2;

        @media (min-width: $container-md) {
          font-size: 40px;
        }
      }

      h4, a {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.44;
        margin: 20px 0;
      }
    }
  }
}

// -----------------------------

.back-button {
  display: block;
  padding-top: 10px;
}

.flex-container {
  -webkit-justify-content: space-between;
  display: -webkit-flex;
  display: flex; // sass-lint:disable-line no-duplicate-properties
  justify-content: space-between;

  .day {
    width: 30%;
  }

  .month {
    width: 30%;
  }

  .year {
    width: 30%;
  }
}

.small-gray-horz-rule {
  border-bottom: 1px solid $color-horiz-divider;
  display: block;
  margin-bottom: 35px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 35px;
  @media (min-width: $container-md) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#select-main {

  #select-filter {
    background-color: $color-light-gray;
    border: 1px solid $color-gray-border;
    margin-bottom: 0;
    margin-top: 25px;
    padding: 20px;
    text-align: left;
    @media (min-width: $container-md) {
      margin-top: 38px;
    }

    h2 {
      color: $color-text-title;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
  }

  .select-result {
    border: 1px solid $color-gray-border;
    border-top: 0;
    padding: 20px;
    text-align: left;
    @media (min-width: $container-md) {
      min-height: 100px;
      padding: 20px 20px 0;
    }

    .result-details {
      margin-bottom: 14px;

      h2 {
        color: $color-text-title;
        font-size: 20px;
        line-height: 20px;
        margin: 0 0 4px;
      }

      p {
        color: $color-text-default;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 2px;

        span.city-state {
          color: $color-text-title;
          font-weight: 600;
        }
      }
    }

    .result-button {
      margin-bottom: 0;
      text-align: center;
    }
  }

  #dont-see-profile-text-link {
    margin-top: 40px;
  }

  .use-pointer-cursor {
    cursor: pointer;
  }
}

// -----------------------------

#edit-main {

  text-align: left;

  .languages-outer {
    margin-bottom: 15px;

    @media (min-width: $container-sm) {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

  .languages-spoken {
    margin-bottom: 10px;
    margin-top: 5px;

    @media (min-width: $container-sm) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .languages-select-results {
    margin-top: 0;

    @media (min-width: $container-sm) {
      margin-top: 15px;
    }

    @media (min-width: $container-md) {
      margin-top: 0;
    }
  }

}

// -----------------------------

#account-main {

  .container {
    text-align: left;

    p {
      font-weight: 600;
    }

    #terms-group p {
      font-weight: 400;
    }

  }

  #example-photo {
    color: $color-text-title;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    img {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  #bio-pic-upload {

    .bio-pic-instructions {
      margin-bottom: 50px;
    }

    h3 {
      color: $color-text-title;
      font-size: 18px;
    }

    #photo-upload-placeholder {
      img {
        cursor: pointer;
        display: block;
        margin: 0 auto 12px;
        max-width: 100%;
        width: 200px;
      }
    }

    #bio-pic-instructions {
      color: $color-text-default;
      margin-bottom: 0;
      padding-bottom: 0;
      text-align: center;
      @media (min-width: $container-sm) {
        text-align: left;
      }

      a#sample-profile {
        display: block;
      }
    }

    #upload-file-button-container {
      margin: 10px 0;
      text-align: center;

      #provider_photo {
        display: none;
      }
    }

    #upload-error {
      color: $color-error;
    }
  }

  #welcome-msg-instructions {
    margin-bottom: 50px;

    h3 {
      color: $color-text-title;
      font-size: 1em;
      font-weight: 600;
    }
  }

  #welcome-msg {

    margin-bottom: 50px;

    p span {
      display: block;
      @media (min-width: $container-sm) {
        float: right;
      }
    }
  }

  #terms-group {
    margin-top: 10px;
  }

  .signature-row {
    p {
      font-weight: 400;
      margin: 10px 0 20px;
    }
  }
}

// -----------------------------

#paid-main, #direct-main {
  padding-top: 25px;
  text-align: left;

  .container {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $container-md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .birthday {
      p {
        margin-bottom: 6px;
      }

      .flex-container {
        -webkit-justify-content: space-between;
        display: -webkit-flex;
        display: flex; // sass-lint:disable-line no-duplicate-properties
        justify-content: space-between;

        .month {
          display: -webkit-flex-item;
          display: flex-item; // sass-lint:disable-line no-duplicate-properties
          height: auto;
          margin-bottom: 0;
          width: 45%;
        }

        .day,
        .year {
          display: -webkit-flex-item;
          display: flex-item; // sass-lint:disable-line no-duplicate-properties
          height: auto;
          margin-bottom: 0;
          width: 25%;
        }
      }
    }
  }

  .modal-body {
    text-align: center;

    img {
      max-width: 792px;
      width: 100%;
    }
  }

  #tax-info-new-address, #bank-info-new-address {
    display: none;
  }

  #tax-section-intro {
    margin-bottom: 15px;
  }

  .gray-well {
    margin-top: 25px;
    @media (min-width: $container-sm) {
      margin-top: 50px;
    }
  }

  #certification {
    text-align: left;
    @media (min-width: $container-md) {
      .left-side {
        padding-left: 0;
      }

      .right-side {
        padding-right: 0;
      }
    }
  }

  .review-section {
    p {
      color: $color-text-default;
      margin-bottom: 21px;
      @media (min-width: $container-sm) {
        text-align: center;
      }
    }
  }
}

#lab-coat-modal {
  .modal-body {
    text-align: left;
  }
}

#check-image-container {
  text-align: center;
}

.policy {
  margin: 0 auto;
  max-width: 1024px;
  text-align: left;

  .modal-header,
  .modal-body {
    @media (min-width: $container-md) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    h1,
    h2,
    h3 {
      margin: 10px 0;
      text-align: left;
    }

    h1 {
      font-size: 1.75em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.25em;
    }
  }

  h2 {
    font-size: 24px;
    margin: 5px 0;
    text-align: center;
  }

  .table-row-first {
    margin-top: 40px;
  }

  .table-row {
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      @media (min-width: $container-md) {
        margin-top: 0;
        padding-right: 20px;
      }
    }
  }

  .table-row-last {
    margin-bottom: 40px;
  }

  .break {
    @media (max-width: $container-sm) {
      word-break: break-all;
    }
  }

  .center {
    text-align: center;
  }

  .indent {
    text-indent: 20px;
  }

  .no-bot-margin {
    margin-bottom: 0;
  }

  .list-bot-margin {
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
  }

  ol {
    margin-bottom: 10px;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.parens-num {
      counter-reset: item;
      margin-left: 0;
      padding-left: 0;
      text-indent: -22px;

      &>li {
        counter-increment: item;
        list-style: none inside;
        margin-left: 20px;
        padding-left: 20px;

        &::before {
          content: '(" counter(item) ")';
          padding-right: .5em;
        }
      }
    }
  }
}

// -----------------------------

#verify-main {
  padding-top: 25px;
  text-align: left;

  .container {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $container-md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .gray-well {
    text-align: center;
  }

  .security-question-group {
    margin-bottom: 18px;
  }

  .verification-choices {
    list-style: none;

    label {
      font-weight: normal;
    }
  }
}


// application heros

// step 1
#app-register-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step1_RegisterToPractice.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step1_RegisterToPractice.jpg');
  }
}

// step 2
#app-select-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step2_SelectYourProfile.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step2_SelectYourProfile.jpg');
  }
}

// step 3
#app-edit-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step3_EditYourProfile.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step3_EditYourProfile.jpg');
  }
}

// step 4
#app-customize-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step4_CustomizeYourPractice.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step4_CustomizeYourPractice.jpg');
  }
}

// step 5
#app-paid-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step5_GetPaid.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step5_GetPaid.jpg');
  }
}

// step 6
#app-verify-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step6_IDVerification.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step6_IDVerification.jpg');
  }
}

#app-account-hero {
  background-image: url('#{$pixel-path-images}/3128x880_Step_CreateYourAccount.jpg');
  @media (min-width: 1564px) {
    background-image: url('#{$pixel-path-images}/4000x880_Step_CreateYourAccount.jpg');
  }
}

.error {
  color: $color-error;
}

.notice {
  border: 2px solid $color-science-blue;
  margin-top: 40px;
  min-height: 104px;
  padding: 20px 20px 20px 100px;

  &.error {
    border-color: $color-error;
  }

  .icon-AW_Information::before {
    color: $color-icon;
    content: '\e90f';
    font-size: 63px;
    left: 42px;
    line-height: 63px;
    position: absolute;
  }
}

.full .notice .icon-AW_Information::before {
  left: 36px;
  @media (min-width: $container-sm) {
    left: 23px;
  }
}
