@use 'sass:math';

@import 'base';

$nav-slider-width: 760px;
$nav-height: 54px;
$nav-cta-height: 34px;
$nav-logo-height: 38px;

$color-nav-background: rgba(0, 0, 0, .45);
$color-nav-slider-background: rgba(0, 0, 0, .8);

#header {
  background-color: $color-nav-background;
  height: $nav-height;
  position: fixed;
  top: 0;
  transition: top .2s ease-in-out;
  width: 100%;
  z-index: 9999;

  @media (max-width: $container-xsm) {
    &.auto-hide:not([data-type='application']) {
      top: -$nav-height;
    }
  }

  #nav-primary {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: $nav-height;
    margin: 0 auto;
    max-width: 960px;
    overflow: hidden;

    #logo {
      border: 0;
      float: left;
      height: $nav-logo-height;
      margin-left: 10px;
      margin-top: math.div($nav-height - $nav-logo-height, 2);
    }

    #nav-controls {
      float: right;
      height: $nav-height;
      margin-right: 10px;
      overflow: hidden;

      .cta {
        background-clip: padding-box;
        border-radius: 5px;
        border: 1px solid $color-amwell-button-green;
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
        line-height: $nav-cta-height - 2; // -2 to account for border
        margin-top: math.div($nav-height - $nav-cta-height, 2);
        outline: 0;
        padding: 0 10px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;

        &:hover {
          background-color: $color-amwell-button-green-hover;
          border-color: $color-amwell-button-green-hover;
        }

        &.highlight {
          background-color: $color-amwell-button-green;
          border-color: $color-amwell-button-green;

          &:hover {
            background-color: $color-amwell-button-green-hover;
            border-color: $color-amwell-button-green-hover;
          }
        }

        &.cta-application {
          display: none;
        }
      }
    }
  }

  // Display rules for header when in application process
  &[data-type='application'] {
    .cta {
      display: none !important;
    }

    .cta-application {
      display: inline-block !important;
    }
  }

  // Display rules for header when only logo should be shown
  &[data-type='logo-only'] {
    #nav-controls {
      display: none !important;
    }
  }
}

#nav-slider {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $color-nav-slider-background;
  display: none;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 888;

  @media (max-width: $nav-slider-width) {
    &.is-active {
      display: block;
    }
  }
}

@media (max-width: $nav-slider-width) {
  html.no-scroll,
  html.no-scroll body {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  html.no-scroll body {
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
  }
}

@keyframes flyin {
  from {
    left: 100%;
  }
}

@keyframes flyout {
  to {
    left: 100%;
  }
}
