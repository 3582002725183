// variables
$pixel-path-fonts: "../../../../pixel/assets/fonts";
$pixel-path-images: "../../../../pixel/assets/images";

// Bootstrap grid
// --------------
$container-xs:  320px;
$container-xsm: 480px;
$container-sm:  630px;
$container-md:  768px;
$container-mdl: 992px;
$container-lg: 1199px;

// Colors
// ------

// Default color scheme from business site
$color-biloba-flower: #9485ed;
$color-bright-sun: #fcd54e;
$color-carrot-orange: #ef9c23;
$color-dark-gray: #313336;
$color-dodger-blue:  #25abfd;
$color-forest-green: #30a039;
$color-light-gray: #f2f2f2;
$color-lochmara: #007ac9;
$color-pastel-green: #71df54;
$color-saffron: #f5b838;
$color-scampi: #5d51a2;
$color-science-blue: #0469bd;
$color-slate-gray: #6e8093;

// Custom Pixel application colors
$color-black: #000;
$color-divider: #d2d2d2;
$color-error: #a94442;
$color-success: #33aa00;
$color-gray-border: #cbcbcb;
$color-gray: #ccc;
$color-icon: #96a0ac;
$color-text-default: #666;
$color-text-title: #333;
$color-white: #fff;
$color-img-border: #d3d7d8;
$color-medium-gray: #aaa;

// Button color defs from business site
$color-amwell-button-blue: $color-dodger-blue;
$color-amwell-button-blue-hover: #1a99e8;
$color-amwell-button-green: #66d448;
$color-amwell-button-green-hover: #5bc23e;
$color-amwell-button-yellow: #fdb53e;
$color-amwell-button-yellow-hover: #eda42f;
$color-amwell-button-red: #fd6b6b;
$color-amwell-button-red-hover: #e85858;
$color-amwell-button-purple: $color-biloba-flower;
$color-amwell-button-purple-hover: #7a6bcf;
$color-amwell-button-darkblue: #1774cc;
$color-amwell-button-darkblue-hover: #12aefc;

// Element specific colors
$color-horiz-divider: $color-gray;

// Mixins
// ------

// buttons (port from bme/americanwell.com)
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  border-radius: $border-radius;
  font-size: $font-size;
  padding: $padding-y $padding-x;
}

@mixin background-gradient($start-color, $end-color, $orientation) {
  // sass-lint:disable-block no-duplicate-properties
  background-color: $start-color;
  @if $orientation == 'vertical' {
    background-image: -webkit-linear-gradient(top, $start-color, $end-color);
    background-image: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background-image: -webkit-linear-gradient(left, $start-color, $end-color);
    background-image: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background-image: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background-image: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
