@import 'base';

// the following is a direct pickup from the business site
// with the following modifications:
// - color literals replaced with Sass vars
// - add div and label to .aw-btn styles
// - add cursor: pointer to .aw-btn styles
// - remove outline: none from focus state

// Buttons
$aw-btn-cta-padding-x: 20px;
$aw-btn-cta-padding-y: 6px;
$aw-btn-cta-font-size: 20px;
$aw-btn-cta-border-radius: 4px;

$aw-btn-lg-padding-x: 20px;
$aw-btn-lg-padding-y: 6px;
$aw-btn-lg-font-size: 20px;
$aw-btn-lg-border-radius: 4px;

$aw-btn-md-padding-x: 20px;
$aw-btn-md-padding-y: 6px;
$aw-btn-md-font-size: 16px;
$aw-btn-md-border-radius: 4px;

$aw-btn-sm-padding-x: 14px;
$aw-btn-sm-padding-y: 6px;
$aw-btn-sm-font-size: 14px;
$aw-btn-sm-border-radius: 4px;


input[type="submit"],
input[type="reset"],
input[type="button"],
div,
label,
button,
a {
  &.aw-btn {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-clip: padding-box; // if gradient or images used this is required
    border: 2px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    @include button-size($aw-btn-md-padding-y, $aw-btn-md-padding-x, $aw-btn-md-font-size, $aw-btn-md-border-radius);

    &:hover, &:focus {
      text-decoration: none;
    }

    // Disabled comes first so active can properly restyle
    &:disabled, &[disabled] {
      cursor: not-allowed;
      opacity: .65;
      pointer-events: none;
    }

    &:active {
      background-image: none;
    }

    // When using the "ArrowRight" glyph within the context of the button this
    // provides size proportional to the buttons text. Ideally the a new glyph
    // would be created for this purpose that is sized correctly for its intended
    // purpose (e.g., "Read More >").
    .icon-AW_ArrowRight  {
      font-size: .6em;
    }
  }

  // Sizes

  &.aw-btn-cta {
    min-width: 200px;
    @include button-size($aw-btn-cta-padding-y, $aw-btn-cta-padding-x, $aw-btn-cta-font-size, $aw-btn-cta-border-radius);
  }

  &.aw-btn-lg {
    @include button-size($aw-btn-lg-padding-y, $aw-btn-lg-padding-x, $aw-btn-lg-font-size, $aw-btn-lg-border-radius);
  }

  &.aw-btn-md {
    @include button-size($aw-btn-md-padding-y, $aw-btn-md-padding-x, $aw-btn-md-font-size, $aw-btn-md-border-radius);
  }

  &.aw-btn-sm {
    @include button-size($aw-btn-sm-padding-y, $aw-btn-sm-padding-x, $aw-btn-sm-font-size, $aw-btn-sm-border-radius);
  }


  &.aw-btn-blue {
    background-color: $color-amwell-button-blue;
    border-color: $color-amwell-button-blue;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-blue-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-blue-hover;
    }
  }

  &.aw-btn-green {
    background-color: $color-amwell-button-green;
    border-color: $color-amwell-button-green;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-green-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-green-hover;
    }
  }

  // "orange" is legacy and closest to "yellow" after old class usage
  // has been removed "orange" can be dropped
  &.aw-btn-yellow,
  &.aw-btn-orange {
    background-color: $color-amwell-button-yellow;
    border-color: $color-amwell-button-yellow;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-yellow-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-yellow-hover;
    }
  }

  &.aw-btn-red {
    background-color: $color-amwell-button-red;
    border-color: $color-amwell-button-red;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-red-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-red-hover;
    }
  }

  &.aw-btn-purple {
    background-color: $color-amwell-button-purple;
    border-color: $color-amwell-button-purple;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-purple-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-purple-hover;
    }
  }

  &.aw-btn-darkblue {
    background-color: $color-amwell-button-darkblue;
    border-color: $color-amwell-button-darkblue;
    color: $color-white;
    &:hover {
      background-color: $color-amwell-button-darkblue-hover;
    }
    &:hover:not(:focus) {
      border-color: $color-amwell-button-darkblue-hover;
    }
  }

  &.aw-btn-outlines {
    background-color: $color-white;
    border-color: $color-amwell-button-darkblue;
    border-width: 1px;
    color: $color-amwell-button-darkblue;
    &:hover, &:focus {
      border-color: $color-amwell-button-darkblue-hover;
      color: $color-amwell-button-darkblue-hover;
      box-shadow: none; // No box-shadow for "outlines" button
    }
  }

  &.aw-btn-reverse {
    border-color: $color-white;
    border-width: 1px;
    color: $color-white;
    &:hover {
      background-color: $color-white;
      color: $color-amwell-button-darkblue-hover;
    }
  }

}
